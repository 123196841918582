<template lang="pug">
div
  input(class="font-size-1--0625 global-integration-name-edit global-integration-name-edit-ellipsis dynamic-input"
    type="text"
    v-autowidth="{minWidth: '20px', comfortZone: 20}"
    v-model="integrationName"
    ref="integrationNameInput"
    @click.stop
  )
  template(v-if="edit")
    i.fa.fa-close.cursor-pointer.p-1.orange-500(@click.stop="cancel")
    i.fa.fa-check.cursor-pointer.p-1.orange-500(@click.stop="saveName")
  template(v-else)
    i(class="fa fa-pencil ml-2"
      aria-hidden="true"
      @click.stop="editMode")
</template>

<script>
  import CHANGE_GLOBAL_INTEGRATION_NAME from '@/graphql/ChangeGlobalIntegrationName.gql';
  import { get as _get } from 'lodash-es';

  export default {
    name: 'IntegrationName',
    props: {
      name: { type: String },
      integrationId: { type: String },
    },
    data() {
      return {
        edit: false,
        initialName: null,
        integrationName: null,
      };
    },

    created() {
      this.initialName = this.name;
      this.integrationName = this.name;
    },

    methods: {
      addListener() {
        window.addEventListener('keydown', this.listenToKeydown);
      },
      removeListener() {
        window.removeEventListener('keydown', this.listenToKeydown);
      },
      listenToKeydown(e) {
        if (e.key !== 'Enter') {
          return;
        }

        this.saveName();
        this.removeListener();
      },
      editMode() {
        this.edit = true;
        this.$refs.integrationNameInput.focus();
        this.addListener();
      },
      async saveName() {
        const newName = this.$refs.integrationNameInput.value;

        const result = await this.$apollo.mutate({
          mutation: CHANGE_GLOBAL_INTEGRATION_NAME,
          variables: {
            integrationId: this.integrationId,
            name: newName,
          },
        });

        const changeIntegrationName = _get(result, 'data.changeIntegrationName');

        if (changeIntegrationName) {
          this.$emit('update:name', newName);
          this.$emit('successfulChange', { integrationId: this.integrationId, newName });
          this.initialName = newName;
          this.$refs.integrationNameInput.blur();
        } else {
          this.$emit('failedChange', { integrationId: this.integrationId });
        }

        this.edit = false;
      },

      cancel() {
        this.$refs.integrationNameInput.blur();
        this.integrationName = this.initialName;
        this.edit = false;
      },
    },
  };
</script>
